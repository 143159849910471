// Suppress React defaultProps warnings coming from external libraries.
const originalConsoleError = console.error
console.error = (...args) => {
  if (
    args[0] &&
    typeof args[0] === 'string' &&
    args[0].includes('Support for defaultProps will be removed from function components')
  ) {
    return
  }
  originalConsoleError(...args)
}